import React from 'react';
import Box from 'components/box';
import Layout from 'components/layout';
import FullWidth from 'components/fullwidth';
import { Link } from 'gatsby';

const NotFound = () => (
  <Layout>
    <FullWidth>
      <Box>
        <h2>Stránka nenalezena (404)</h2>
        <Link to="/">Zpět na hlavní stranu</Link>
      </Box>
    </FullWidth>
  </Layout>
);

export default NotFound;
